import React from 'react';

const AjaxAPI = window.AjaxAPI;

class Fetch extends React.Component {
  constructor(props) {
    super(props);

    this.key = this.props.signalsKey

    this.fetch()
  }

  componentDidUpdate(prevProps) {
    this.fetch()
  }

  fetch() {
    const key = this.key;
    const url = this.props.url || key
    const signals = appState.getNamedSignals()[key]

    if (url && (!signals || !signals.list)){
      (new AjaxAPI).get(url, function(data){
        const list = data.data
        appState.setNamedSignals([
          { key: key, signal: 'list', data: list }
        ])
      })
    }
  }

  render() {
    return null
  }
}

export default Fetch
